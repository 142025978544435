<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label="status"
              label-for="status"
            >
              <b-form-select
                id="status"
                v-model="editPaymentStatusForm.status"
                :state="getValidationState(validationContext)"
                :options="optionStatus"
                trim
                placeholder="status"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="method"
            rules="required"
          >
            <b-form-group
              label="method"
              label-for="method"
            >
              <b-form-select
                id="method"
                v-model="editPaymentStatusForm.method"
                :state="getValidationState(validationContext)"
                :options="optionMethod"
                trim
                placeholder="method"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="editPaymentStatus()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="editPaymentStatus()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {},
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      optionMethod: [
        { value: 'visa', text: 'Visa' },
        { value: 'cash', text: 'Cash' },
        { value: 'check', text: 'Check' },
        { value: 'wallet', text: 'Wallet' },
        { value: 'wallet', text: 'Wallet' },
        { value: 'bank transfer', text: 'Bank Transfer' },
      ],
      optionStatus: [
        { value: 'paid', text: 'Paid' },
        { value: 'pending', text: 'Pending' },
        { value: 'proceed', text: 'Proceed' },
        { value: 'canceled', text: 'Canceled' },
      ],
      loader: false,
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const editPaymentStatusForm = ref({})
    return {
      getValidationState,
      editPaymentStatusForm,
    }
  },
  // mounted() {
  //   this.showEngineer();
  // },

  methods: {
    editPaymentStatus() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.editPaymentStatusForm) {
          formData.append(key, this.editPaymentStatusForm[key])
        }

        axios
          .post(`requests/${this.$route.params.id}/payment_status`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'requests' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
